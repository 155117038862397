import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description: string;
  url?: string;
  image_url?: string;
}

function SEO({ title, description, url, image_url }: Props): JSX.Element {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || 'https://www.saberastro.com/'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={image_url || 'https://assets.saberastro.com/img/thumbnail.jpg'}
      />

      <meta name="author" content="Saber Astronautics" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default SEO;
